.mobile{
  .slide1 {
    background: url("../../../images/mslide1.jpg") center center / cover no-repeat,
    rgb(207, 191, 142);
  }
  .slide1-logo {
    align-self: center;
    width: 50vw;
  }
  .swiper-slide-active .slide1-logo {
    animation: logo-animate 0.5s linear;
  }

  @keyframes logo-animate {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(1);
    }
    85% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }
}


