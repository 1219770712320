.step-list {
  display: flex;
  flex-direction: column;
}
.step-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
  height: 3vh;
  width: 0.4vh;
  font-size: 14px;
  color: #c4bbab;
  white-space: nowrap;
  cursor: pointer;
  background: #000000;
  opacity: 0.2;
  &.step-list-item-active {
    background: #aa8641;
    opacity: 1;
  }
}
