.pc {
  .silde4-bg-text {
    background: url(~@/images/slide4-text.png) center center / cover;
  }
  .slide4-text-wrapper2 {
    top: unset;
    bottom: 5vh;
  }
  .slide4 {
    background: url("../../../images/slide4-bg.jpg") center center / cover
      no-repeat;
    flex-direction: column;
    align-items: flex-start;
  }
  &.mobile-horizontal .slide4-text-wrapper {
    max-width: 40vw;
    font-size: 0.25rem;
    p:nth-child(2) {
      font-size: 0.13rem;
    }
  }

  .slide4-text-wrapper {
    padding-left: 5vw;
    font-size: 50px;
    max-width: 27vw;
    text-align: left;
    transform: translateY(-20%);

    > p:first-child {
      color: #aa8641;
    }
    > p:nth-child(2) {
      color: #717171;
      font-size: 20px;
      line-height: 1.7;
      margin-top: 1em;
    }
    .mini-program {
      display: flex;
      margin-top: 2vw;
      align-items: center;
      color: #999999;
      img {
        width: 6vw;
      }
      p {
        width: 8em;
        line-height: 1.2;
        font-size: 18px;
        margin-left: 2em;
      }
    }
  }
  .slide4-subslide {
    position: absolute;
    top: 5vh;
    right: 9vw;
    background: transparent;
    width: 100vh;
    max-width: 50vw;
    height: 90vh;
    overflow: hidden;
  }
  .slide4-subslide1 {
    position: relative;
    width: 100vh;
    height: 90vh;
    background: url('https://static.svip.game.qq.com/ca913de1b54bce48f426b769f577ccba.png') left center / contain
      no-repeat;
  }
  .swiper-slide-active .slide4-subslide .slide4-subslide1 {
    animation: slide4-right2left 1s ease-in-out;
  }
  @keyframes slide4-right2left {
    from {
      left: 100%;
    }
    to {
      left: 0;
    }
  }
  .slide4-subslide1-logo1 {
    position: absolute;
    opacity: 0;
    width: 16vh;
    left: 5vh;
    top: 5vh;
  }
  .swiper-slide-active
    .slide4-subslide
    .swiper-slide-active
    .slide4-subslide1-logo1 {
    animation: slide4-subslide1-logo1-step1 1s forwards;
    animation-delay: 0.5s;
  }
  @keyframes slide4-subslide1-logo1-step1 {
    from {
      opacity: 1;
      top: 2vh;
    }
    to {
      opacity: 1;
      top: 16vh;
    }
  }
  .slide4-subslide1-logo2 {
    position: absolute;
    opacity: 0;
    width: 8vh;
    left: 90vh;
    top: 43vh;
  }
  .swiper-slide-active
    .slide4-subslide
    .swiper-slide-active
    .slide4-subslide1-logo2 {
    animation: slide4-subslide1-logo1-step2 1s forwards;
    animation-delay: 0.9s;
  }

  @keyframes slide4-subslide1-logo1-step2 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.slide4-business {
  position: absolute;
  padding-left: 5vw;
  bottom: 4vh;
  font-size: 1vw;
  display: flex;
  align-items: center;
  z-index: 10;
  text-align: left;
  button {
    color: currentColor;
    cursor: pointer;
  }
  a {
    &:visited,
    &:active,
    &:link {
      color: currentColor;
    }
    cursor: pointer;
    text-decoration: none;
  }
}
.mobile-horizontal {
  .slide4-business-image {
    width: 12vw;
  }
  .slide4-business-text {
    font-size: 0.08rem;
  }
}
&.mobile-horizontal .network-supervisor {
  font-size: 1.2vw;
  img {
    width: 4vw;
    height: 5vw;
  }
}
.network-supervisor {
  margin-left: 5vw;
  margin-right: 2vw;
  display: flex;
  border: 1px solid #ccc;
  color: #aaa;
  font-size: 16px;
  align-items: center;
  padding: 0.5vw 1vw;
  background: #fff;
  img {
    height: 80px;
    width: 60px;
    margin-right: 1em;
  }
}
.slide4-business-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  p {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(2) {
      font-size: 0.8em;
    }
    &:nth-child(3) {
      color: #c4bbab;
      font-size: 0.6em;
    }
    &:nth-child(4) {
      color: #c4bbab;
      font-size: 0.6em;
    }
  }
}
