.pc {
  &.mobile-horizontal .slide3 {
    background: url(~@/images/slide3-bg-hor.png) center bottom / 100% no-repeat,
      rgb(250, 249, 236);
  }
  @media (max-aspect-ratio: ~"600 / 290") {
    &.mobile-horizontal .slide3 {
      background: url(~@/images/slide3-bg-hor.png) center center / 100%
          no-repeat,
        rgb(250, 249, 236);
    }
  }
  .slide3 {
    background: url(~@/images/slide3-bg.png) right 75% no-repeat,
      linear-gradient(to top, #f0e8d1, #faf9ec);
    background-size: 85% auto;
  }
  &.mobile-horizontal .slide3-hat {
    left: 10vw;
    right: unset;
    animation: hat 2s linear infinite alternate;
  }

  .slide3-hat {
    position: absolute;
    width: 28vw;
    right: 50vw;
    top: calc(50% - 12vw);
    animation: hat 2s linear infinite alternate;
  }

  @keyframes hat {
    from {
      transform: translateY(20%);
    }
    to {
      transform: translateY(5%);
    }
  }

  .slide3-text {
    position: absolute;
    right: 24vw;
    top: 54%;
    transform: translateY(-50%);
  }
  &.mobile-horizontal .slide3-text {
    top: 54%;
  }
  &.mobile-horizontal .slide3-text-list {
    font-size: 0.18rem;
  }
  .slide3-text-list {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 40px;
  }
  .slide3-text-list:not(:first-child) {
    margin-top: 0.8em;
  }
  .slide3-text-title p:first-child {
    font-size: 0.8em;
    font-weight: bold;
  }
  &.mobile-horizontal .slide3-text-title p:first-child span {
    margin-left: 0.5em;
  }
  &.mobile-horizontal .slide3-text-title p:nth-child(2) {
    font-size: 0.6em;
  }
  .slide3-text-title p:first-child span {
    display: inline-block;
    font-size: 0.7em;
    margin: 0 0 0.5em 0;
    font-weight: normal;
  }
  .slide3-text-title p:nth-child(2) {
    display: flex;
    align-items: center;
    font-size: 0.36em;
    color: #967941;
    img {
      width: 1em;
      margin-right: 0.7em;
    }
    img:not(:first-child) {
      margin-left: 0.5em;
    }
  }
  .slide3-text-title-image {
    align-self: flex-start;
    margin-top: .3em;
    width: 1.2vw;
    margin-right: 1.2vw;
  }
}
