.v-whole{
  .head{
    width: 100%;
    height:1.67rem;
    background: url(../../images/v-head.png)  center center / contain no-repeat;
    .back-btn {
      position: absolute;
      top: .4rem;
      left: .6rem;
      width: 1.4rem;
      height: .5rem;
      font-size: .25rem;
      text-align: center;
      line-height: .5rem;
      color: #af9054;
      border: 1px solid;
      border-radius: .1em;
    }
    .back{
      position: absolute;
      top: .2rem;
      left: .6rem;
      width:1.4rem;
      height:.8rem;
      font-size: 0;
      background: url(../../images/back-botton.png)  center center / contain no-repeat;
    }
  }
  .main{
    font-size: 0;
    .main-head{
      font-size: .34rem;
      display: flex;
      justify-content:space-evenly;
      border-bottom: .01rem solid #d6d6d6;
      > div{
        padding:.16rem 0;
        width: 40%;
        text-align: center;
      }
      .select{
        color:#aa8641;
        border-bottom: .02rem solid #aa8641;
      }
    }
    img{
       width: 100%;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       position: relative;
    }
  }

  .foot-head{
    width:100%;
    height:7.44rem;
    background: url(../../images/v-foot.png)  center center / contain no-repeat;
  }
  .slide4-text-wrapper{
    padding-left: 5vw;
    text-align: left;
    transform: translateY(70%);
    > p{
      font-size: .06rem;
      color: #e5e5e5;
    }
    > p:nth-child(1) {
      font-size: .08rem;
    }
  }
  .foot-main{
    display: flex;
    transform: translateY(90%);
    .text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: .06rem;
      p{
        &:first-child {
          color: #f9f9f9;
        }
        &:nth-child(2) {
          color: #aa8641;
          font-size: 0.06rem;
          margin-top: .04rem
        }
      }
    }
  }
  .network-supervisor {
    width:.6rem;
    margin-left:.4rem;
    margin-right:.1rem;
    display: flex;
    border:  .01rem solid #ccc;
    color: #aaa;
    font-size:  .06rem;
    align-items: center;
    padding: 0.2vw .6vw;
    background: #fff;
    img {
      height:  .24rem;
      width:   .24rem;
      margin-right: .08rem;
    }
  }
  .slide4-business-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .06rem;
    color: currentColor;
    background-color:#17141a;
    text-align: center;
    padding: .14rem;
    p {
      width: 80%;
      margin:0 auto;
      margin-top: 1em;
      color:#898989;
      a{
        color:#898989;
        text-decoration: none;
      }
      &:first-child {
        font-size: 0.6em
      }
      &:nth-child(2) {
        color: #898989;
        font-size: 0.4em;
      }
    }
  }
}