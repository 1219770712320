html {
  font-size: 13.333333333333333vw;
}

.App {
  text-align: center;
}

ul,
li,
p {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  outline: none;
  font-size: inherit;
  padding: 0;
}

.home-step {
  position: absolute;
  z-index: 10;
  left: 3vw;
  top: 50%;
  transform: translateY(-50%);
}

.home-logo {
  position: absolute;
  z-index: 2;
  left: 5vw;
  top: 5vh;
  width: 180px;
}

.home-swiper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.home-swiper > .swiper-wrapper {
  flex-direction: column;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-pre-slide {
  position: absolute;
  top: .5vw;
  right: 50vw;
  width: 2vw;
  height: 2.5vw;
  border: 0;
  outline: none;
  background: url(~@/images/m-arrow-up.png) center center / contain no-repeat;
  z-index: 2;
  animation: home-slide-animate2 2s infinite alternate;
}

.home-next-slide {
  position: absolute;
  border: 0;
  outline: none;
  bottom: 0;
  right: 50vw;
  width: 2vw;
  height: 2.5vw;
  background: url(~@/images/m-arrow-up.png) center center / contain no-repeat;
  transform: rotateZ(180deg);
  z-index: 2;
  animation: home-slide-animate 2s infinite alternate;
}

.home-next-slide[disabled],
.home-pre-slide[disabled] {
  display: none;
}

@keyframes home-slide-animate {
  from {
    bottom: 0;
  }

  to {
    bottom: 1vw;
  }
}

@keyframes home-slide-animate2 {
  from {
    top: 0;
  }

  to {
    top: 1vw;
  }
}

.modals {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: inline-block;
  z-index: 999;
}

.close {
  width: 5.2vw;
  height: 5.2vw;
  margin-left: auto;
  background-image: url(https://static.xinyue.qq.com/ceiba/act/335591/close.png);
  background-size: 100% 100%;
  font-size: 0;
  z-index: 999;
}

.rules {
  display: block;
  width: 100%;
  height: 20vw;
  text-align: center;
  font-size: 0;
  background: url(https://ossweb-img.qq.com/images/tgclub/act/20201212cj/fc-bdd.png) center center / contain no-repeat;
  background-size: 100% 100%;
}

.button {
  display: block;
  width: 17.4vw;
  height: 5vw;
  margin: 11vw auto 0;
  background-image: url(https://static.xinyue.qq.com/ceiba/act/335591/fc-botton.png);
  background-size: 100% 100%;
}

.modals.pc .rules {
  width: 100%;
  height: 12vh;
  text-align: center;
  font-size: 0;
  background: url(https://ossweb-img.qq.com/images/tgclub/act/20201212cj/hcfc-bdd.png) center center / contain no-repeat #070608;
  opacity: .7;
}

.modals.pc .close {
  width: .2rem;
  height: .2rem;
  position: absolute;
  right: 0;
  background-image: url(https://static.xinyue.qq.com/ceiba/act/335591/close.png);
  background-size: 100% 100%;
  font-size: 0;
}

.modals.pc .button {
  position: absolute;
  top: .1rem;
  left: 70%;
  display: block;
  width: .8rem;
  height: .24rem;
  margin: 0;
  background: url(https://static.xinyue.qq.com/ceiba/act/335591/fc-botton.png) center center / contain no-repeat;
  background-size: 100% 100%;
}
