.mobile {
  /* slide2 css */
  .slide2-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../../../images/MobileStewardSlide1.jpg) no-repeat center / cover;
  }

  .logo{
    position: absolute;
    top: 0.8rem;
    left: 0.45rem;
    width: 1.8rem;
    height: 0.55rem;
    background: url(../../../images/mhost-logo.png) center / cover;
  }

  .slide2-text-wrapper {
    position: absolute;
    top: 0.53rem;
    display: flex;
    right: 0rem;
    padding-right: 0.45rem;
    width: 4.31rem;
    height: 1.13rem;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    color: #ffffff;
    z-index: 1;
    box-sizing: border-box;
    background: url(../../../images/mslide2-text-bg.png) no-repeat center / contain;
  }
  .silde2-title {
    text-align: center;
    font-size: 0.44rem;
    //font-style: italic;
  }
  .slide2-text {
    font-size: 0.24rem;
    font-family: normal;
  }

  .aboutus{
    position: absolute;
    left: 50%;
    bottom: 1.3rem;
    margin-left: -1.62rem;
    width: 3.24rem;
    height: 0.92rem;
    outline: none;
    border: none;
    background-color: #fff;
    background-image: url(../../../images/mabout.png);
    background-size: 100% 100%;
  }

  .slide2-subslide2-cube,
  .slide2-subslide2-round {
    transform: scale(0);
  }
  .slide2-subslide2-cube {
    position: absolute;
    top: 2.1rem;
    left: 0.4rem;
    width: 15vw;
    height: 15vw;
    background: url(~@/images/cube.svg) center center / cover no-repeat;
  }
  .slide2-subslide2-round {
    position: absolute;
    top: 1.5rem;
    right: 16vw;
    width: 15vw;
    height: 15vw;
    background: url(~@/images/round.svg) center center / contain no-repeat;
  }
  .swiper-slide-active .slide2-subslide2-cube,
  .swiper-slide-active .slide2-subslide2-round {
    animation: show 0.8s ease-in-out forwards;
  }
  @keyframes show {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}



