body {
  margin: 0;
  font-family: 'Microsoft Yahei',
    'PingFang SC',
    'Avenir',
    'Segoe UI',
    'Hiragino Sans GB',
    STHeiti,
    'Microsoft Sans Serif',
    'WenQuanYi Micro Hei',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
