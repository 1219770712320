.mobile {
  .slide3 {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../../../images/mslide3.jpg) no-repeat center top / contain, #fff;
    .slide2-text-wrapper{
      background: url(../../../images/mside3-titlebg.png) no-repeat center / contain;
    }
  }
  .slide3-hat {
    position: absolute;
    width: 3.9rem;
    height: 3.67rem;
    left: 0.7rem;
    top: 9.6rem;
    animation: hat-animate 2s linear infinite alternate;
  }

  @keyframes hat-animate {
    from {
      top: calc(9.6rem - 0.5vw);
    }
    to {
      top: calc(9.6rem - 0.7rem);
    }
  }
  @media screen and (min-width: 760px) {
    .slide3{
      background: url(../../../images/mslide3.jpg) center top / cover !important;
      .slide3-hat{
        display: none;
      }
    }
  }
}

