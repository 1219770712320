.pc {
  .slide1 {
    background: url("~@/images/slide1.jpg") center center / cover no-repeat,
      rgb(207, 191, 142);
  }
  &.mobile-horizontal .slide1-logo {
    width: 20vw;
  }
  .slide1-logo {
    align-self: center;
    width: 350px;
  }
  .swiper-slide-active .slide1-logo {
    animation: logo-animate 0.5s linear;
  }

  @keyframes logo-animate {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(1);
    }
    85% {
      transform: scale(0.85);
    }
    100% {
      transform: scale(1);
    }
  }

  .slide-fragment {
    position: absolute;
    z-index: 2;
    transition: transform 1.4s linear;
  }
  .slide-fragment1 {
    bottom: 0;
    left: 1vw;
    width: 40vh;
    height: 22vh;
  }
  .slide-fragment2 {
    bottom: 0;
    right: 3vw;
    width: 40vh;
    height: 22vh;
  }
  .slide-fragment3 {
    bottom: 4vh;
    left: 25vw;
    width: 18vh;
    height: 15vh;
  }
  .slide-fragment4 {
    bottom: 8vh;
    left: 35vw;
    width: 12vh;
    height: 10vh;
  }
  .slide-fragment5 {
    bottom: 4vh;
    right: 36vw;
    width: 12vh;
    height: 10vh;
  }
  .slide-fragment6 {
    bottom: 50%;
    right: 15vw;
    width: 12vh;
    height: 10vh;
  }
  .slide-fragment7 {
    bottom: 50%;
    right: 5vw;
    width: 8vh;
    height: 6vh;
  }
}
