.official-model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.official-dialog-pc {
  position: relative;
  width: 50%;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  h3 {
    position: relative;
    display: inline-block;
    align-self: flex-start;
    padding: 0 0.5em 0 1em;
    font-size: 2vw;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: #ffffff;
    margin: 1vw 0 0 -1.1vw;
    background: rgb(170, 134, 65);
    &:before {
      position: absolute;
      left: 0;
      top: -1.1vw;
      content: "";
      border: 0.55vw solid rgb(129, 93, 23);
      border-top-color: transparent;
      border-left-color: transparent;
    }
    &:after {
      position: absolute;
      right: -0.6em;
      top: 0;
      content: "";
      border-style: solid;
      border-width: 2em 0 0 0.6em;
      border-color: transparent transparent transparent rgb(170, 134, 65);
    }
  }
  .official-dialog-text {
    font-size: 1.2vw;
    padding: 1.5vw 3vw 3vw 3vw;
    color: #aa8641;
    line-height: 2;
  }
  .official-close {
    position: absolute;
    right: 1.8vw;
    top: 1.8vw;
    width: 2.5vw;
    height: 2.5vw;
    background: url(../../images/close.svg) center center / cover;
  }
}

.official-dialog-mobile{
  width: 80%;
  h3{
    padding: 0 0.5rem 0 0.4rem;
    height: 0.7rem;
    line-height: 0.7rem;
    font-size: 0.38rem;
    background-color: transparent;
    background-image: url("../../images/maboutTitle.png");
    background-size: 100% 100%;
    &:after{
      border: none;
    }
  }
  .official-close{
    top: 0.2rem;
    right: 0.2rem;
    width: 0.35rem;
    height: 0.35rem;
  }
  .official-dialog-text{
    font-size: 0.24rem;
  }
}
