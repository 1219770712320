.mobile {
  .slide4 {
    background-color: #f0f2f1;
    background-image: url("../../../images/mslide4.jpg");
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
  @media (max-aspect-ratio: ~"1920 / 750") {
    .slide4-text-wrapper {
      transform: translateY(-20%);
    }
  }

  .slide4-subslide {
    position: relative;
    margin-top: 1.6rem;
    background: transparent;
    overflow: hidden;
  }
  .slide4-subslide1 {
    position: relative;
    width: 7.32rem;
    height: 8.23rem;
    background: url('https://static.svip.game.qq.com/ca913de1b54bce48f426b769f577ccba.png') center / contain
      no-repeat;
  }


  .button-group{
    position: absolute;
    right: 0.8rem;
    bottom: 0.7rem;
    font-size: 0;
    z-index: 1000;
    button{
      margin-right: 0.07rem;
      padding: 0;
      width: 0.36rem;
      height: 0.36rem;
      background-color: #cbcacd;
      border: none;
      outline: none;
      transform: skew(-15deg);
    }
    .active{
      background-color: #938d98;
    }
  }

  .slide4-text-wrapper {
    //padding-left: 5vw;
    font-size: 0.36rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0;
    width: 6.6rem;
    text-align: left;
    p:first-child {
      color: #aa8641;
    }
    p:nth-child(2) {
      margin-top: 0.15rem;
      color: #717171;
      font-size: 0.24rem;
      line-height: normal;
    }
    .mini-program {
      margin-right: 0.3rem;
      width: 1.62rem;
      min-width: 1.62rem;
      height: 1.62rem;
      border-radius: 0.1rem;
    }
  }

  .slide4-footer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0.25rem;
    padding: 0 0.28rem;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    .company-detail{
      margin-right: 0.3rem;
      color: #c4bbab;
      font-family: normal;
      .steward{
        font-size: 0.24rem;
        color: #211c25;
        span{
          font-size: 0.28rem;
        }
      }
      .business{
        font-size: 0.24rem;
        a{
          color: #c4bbab;
          text-decoration: none;
        }
        button{
          color: #c4bbab;
          background-color: transparent;
        }
      }
      .copyright{
        font-size: 0.14rem;
      }
    }
    .network-supervisor{
      margin: 0;
      min-width: 2.18rem;
      width: 2.18rem;
      height: 1.07rem;
      justify-content: space-around;
      background-color: #fafafa;
      box-sizing: border-box;
      img {
        height: 0.9rem;
        width: 0.7rem;
        margin: 0;
      }
      .desc{
        font-size: 0.24rem;
      }
    }
  }
  @media screen and (min-width: 760px) {
    .slide4-subslide3-logo1{
      top: 0 !important;
    }
  }
}

