.pc {
  /* slide2 css */
  .slide2-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .slide2-housekeeper-wrapper {
    position: relative;
    display: flex;
    align-self: center;
  }
  .slide2-housekeeper-list {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 35.5vw;
    justify-content: center;
  }

  .slide2-housekeeper {
    height: 100%;
  }
  .slide2-housekeeper:not(:first-child) {
    margin-left: -7.3vw;
  }
  .slide2-housekeeper:last-child {
    margin-left: -7.2vw;
  }
  .swiper-slide-active .slide2-swiper .slide2-housekeeper {
    opacity: 0;
    animation: housekeeper 1s;
    animation-fill-mode: forwards;
  }
  .swiper-slide-active .slide2-swiper .slide2-housekeeper:nth-child(2) {
    animation-delay: 0.5s;
  }
  .swiper-slide-active .slide2-swiper .slide2-housekeeper:nth-child(3) {
    animation-delay: 1s;
  }
  .swiper-slide-active .slide2-swiper .slide2-housekeeper:nth-child(4) {
    animation-delay: 1.5s;
  }
  .swiper-slide-active .slide2-swiper .slide2-housekeeper:nth-child(5) {
    animation-delay: 2s;
  }
  @keyframes housekeeper {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .slide2-joy-butlers {
    position: absolute;
    height: 6.2vw;
    top: 0;
    left: 21.8vw;
    z-index: 1;
  }
  @media (min-aspect-ratio: ~"812 / 357") {
    .slide2-wrapper .slide2-text-wrapper,
    .slide2-wrapper .slide2-subslide2-blank {
      top: unset;
      bottom: 0;
    }
  }

  .slide2-text-wrapper {
    position: absolute;
    top: calc(50% + 16vw);
    display: flex;
    right: 4.2vw;
    width: 100%;
    justify-content: flex-end;
    z-index: 1;
  }
  .slide2-text {
    font-size: 1.7vw;
    font-style: italic;
    margin-right: 1em;
    margin-top: 3vw;
  }
  .silde2-bg-text {
    width: 27vw;
    height: 6vw;
    text-align: center;
    font-size: 2.5vw;
    color: #ffffff;
    background: url(~@/images/slide2-text-bg.png) center center / cover;
  }
  .slide2-about-us {
    position: absolute;
    top: 5vh;
    display: flex;
    right: 3vw;
    z-index: 1;
    color: #aa8641;
    background: transparent;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      height: 20px;
      margin-right: 0.5em;
      object-fit: cover;
    }
  }

  .slide2-subslide2 {
    width: 100%;
    height: 40vw;
    margin-top: 0.7vw;
    background: url(~@/images/slide2-bg.jpg) center center / contain no-repeat;
  }
  .slide2-subslide2-blank {
    position: absolute;
    top: calc(50% + 18.3vw);
    left: 17vw;
    width: 67vw;
    height: 3.5vw;
    border: 1px solid #000;
    background: #ffffff;
    z-index: 2;
  }
  .slide2-subslide2-cube,
  .slide2-subslide2-round {
    transform: scale(0);
  }
  .slide2-subslide2-cube {
    position: absolute;
    top: calc(50% - 13.5vw);
    left: 17.5vw;
    width: 8vw;
    height: 8vw;
    background: url(~@/images/cube.svg) center center / cover no-repeat;
  }
  .slide2-subslide2-round {
    position: absolute;
    top: calc(50% - 8.5vw);
    right: 17.5vw;
    width: 8.2vw;
    height: 8vw;
    background: url(~@/images/round.svg) center center / contain no-repeat;
  }
  .slide2-wrapper .swiper-slide-active .slide2-subslide2-cube,
  .slide2-wrapper .swiper-slide-active .slide2-subslide2-round {
    animation: show 0.8s ease-in-out forwards;
  }
  @keyframes show {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .slide2-subslide3 {
    width: 100vw;
    height: 100vh;
    background: url(~@/images/slide3-bg.jpg) center / cover no-repeat,
      linear-gradient(#fff 50%, rgb(229, 225, 219) 50%);
  }
  .slide2-subslide2-person {
    position: absolute;
    top: calc(50% - 18vw);
    left: 28.5vw;
    height: 40vw;
    width: 45.5vw;
    background: url(~@/images/slide2-person.png) center center / contain
      no-repeat;
    transition: transform 1.4s linear;
  }
  .slide2-sub3-v {
    position: absolute;
    top: calc(50% - 22.7vw);
    left: 18.5vw;
    height: 40vw;
    width: 63.5vw;
    background: url(~@/images/slide2-sub3-v.png) center center / contain
      no-repeat;
  }
  .slide2-subslide3-person {
    position: absolute;
    top: calc(50% - 24vw);
    left: 17.5vw;
    height: 56vw;
    width: 65vw;
    background: url(~@/images/slide3-person.png) center center / contain
      no-repeat;
    transition: transform 1.4s linear;
  }
  .next-slide {
    position: absolute;
    top: -3.2vw;
    right: -0.6vw;
    width: 3vw;
    height: 2.5vw;
    border: none;
    outline: none;
    background: url(~@/images/next.svg) center center / contain no-repeat;
    &[disabled] {
      background: url(~@/images/disablepre.svg) center center / contain
        no-repeat;
    }
  }
  .pre-slide {
    position: absolute;
    border: none;
    outline: none;
    top: -3.38vw;
    right: 2vw;
    width: 3vw;
    height: 2.9vw;
    background: url(~@/images/next.svg) center center / contain no-repeat;
    transform: rotateZ(180deg);
    &[disabled] {
      background: url(~@/images/disablepre.svg) center center / contain
        no-repeat;
    }
  }
}
